import superagent from 'superagent';
import superagentAbsolute from 'superagent-absolute';
import notificationHandler from './notificationHandler';
import store from '../store/index';

const currentLang = localStorage.getItem('i18nextLng');

// const rawToken = localStorage.getItem('lucid-token');
// // eslint-disable-next-line operator-linebreak
// const token =
//   rawToken != null ? `${rawToken}`.substr(10, rawToken.length - 12) : '';

// let agent = superagent.agent();
// agent.on('error', (err) => {
//   notificationHandler(err);
// });

// agent = agent.set('Content-Type', 'application/json');
// agent = agent.set('authorization', `Bearer ${token}`);
// agent = agent.type('json');

// const request = superagentAbsolute(agent)(`${process.env.REACT_APP_API_LINK}`);

export default function getAgentInstance() {
  let agent = superagent.agent();
  let lang;

  if (currentLang === 'en-GB') {
    lang = 'en';
  } else {
    lang = currentLang;
  }

  agent.on('error', (err) => {
    notificationHandler(err);
  });
  const rawToken = localStorage.getItem(process.env.REACT_APP_TOKEN_LOCALSTORAGE_NAME);
  // eslint-disable-next-line operator-linebreak
  const token =
    rawToken != null ? `${rawToken}`.substr(10, rawToken.length - 12) : '';

  // agent = agent.set('Content-Type', 'application/json');
  agent = agent.set(
    'authorization', `Bearer ${store.tokenStore.value || token}`
  );
  agent = agent.set('lang', lang);
  // agent = agent.type('json');

  const request = superagentAbsolute(agent)(
    `${process.env.REACT_APP_API_URL}`
  );
  return request;
}
