import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "mobx-react";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
// import 'antd/dist/antd.css';
import stores from "./store/index";
import "./assets/styles/global.css";
import "./i18n";

var token = localStorage.getItem(process.env.REACT_APP_TOKEN_LOCALSTORAGE_NAME);
if (token) {
  stores.tokenStore.value = token;
}

ReactDOM.render(
  <Provider {...stores}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
